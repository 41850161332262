define("discourse/plugins/chat/discourse/components/chat/routes/threads", ["exports", "@glimmer/component", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/user-threads", "@ember/template-factory", "@ember/component"], function (_exports, _component, _i18n, _navbar, _userThreads, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatRoutesThreads extends _component.default {}
  _exports.default = ChatRoutesThreads;
  _class = ChatRoutesThreads;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-threads">
        <Navbar as |navbar|>
          <navbar.BackButton />
          <navbar.Title
            @title={{i18n "chat.my_threads.title"}}
            @icon="discourse-threads"
          />
  
          <navbar.Actions as |action|>
            <action.OpenDrawerButton />
          </navbar.Actions>
        </Navbar>
  
        <UserThreads />
      </div>
    
  */
  {
    "id": "Adp4Mmcv",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-threads\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"BackButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[28,[32,1],[\"chat.my_threads.title\"],null],\"discourse-threads\"]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"action\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/threads.js",
    "scope": () => [_navbar.default, _i18n.default, _userThreads.default],
    "isStrictMode": true
  }), _class);
});